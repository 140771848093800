.person_picker.entity_picker {
  color: #999999;
}

.person_picker.entity_picker .pickable_entities {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  padding: 0.5em;
  padding-bottom: 1em;
}

.person_picker.entity_picker .pickable_entity {
  flex-basis: 50%;
  max-width: 50%;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
}

.person_picker.entity_picker .unpickable_entity {
  flex-basis: 50%;
  border: none;
  margin: 0;
  padding: 0;
}

.person_picker.entity_picker .badge {
  border: 0.01em solid #999999;
  padding: 0.5em;
  margin: 0.5em;
}

.person_picker.entity_picker .badge .info {
  font-size: 0.8em;
  color: #000;
}

.person_picker.entity_picker .search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
  height: 3em;
  min-height: 3em;
}

.person_picker.entity_picker .search_bar > * {
  margin: 0.5em;
}

.person_picker.entity_picker .search_bar input {
  flex: 1;
  margin-right: 1em;
}
