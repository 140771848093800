.person_carousel {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 0.5em;
}

.person_carousel > div {
  white-space: nowrap;
}

.person_carousel .person {
  display: inline-block;
  padding: 0.5em;
  margin: 0.5em;
  border: 0.01em solid #999999;
}

.person_carousel .person > div {
  flex-direction: column;
}
